
// import NewsCard from '../Components/NewsCard'
import {gurl} from '../Components/Global'
import axios from "axios"
import React,  { useState,useEffect } from 'react'
import { CircularProgress } from '@material-ui/core';
import { HashLink as Linkk } from 'react-router-hash-link';
import "../news.css"

// const NewsDatas = [
//     {
//       '_id': '1',
//       'title': 'Amazing Fifth Title',
//       'image': "http://127.0.0.1:8000/media/dailynews/images/img101.jpg",
//       'description': "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est pariatur nemo tempore repellat? Ullam sed officia iure architecto deserunt distinctio",
//       'date': "Jan 29, 2018"
//     },
//     {
//         '_id': '2',
//         'title': 'Android is full of nonsense privacy issue',
//         'image': "https://images.pexels.com/photos/210243/pexels-photo-210243.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
//         'description': "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est pariatur nemo tempore repellat? Ullam sed officia iure architecto deserunt distinctio",
//         'date': "Jan 29, 2018"
//       },
//       {
//         '_id': '3',
//         'title': 'Iphone i s too much expensive shit',
//         'image': "https://images.pexels.com/photos/248486/pexels-photo-248486.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
//         'description': "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est pariatur nemo tempore repellat? Ullam sed officia iure architecto deserunt distinctio",
//         'date': "Jan 29, 2018"
//       },
//       {
//         '_id': '4',
//         'title': 'amazon is bad river',
//         'image': "https://images.pexels.com/photos/247599/pexels-photo-247599.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//         'description': "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est pariatur nemo tempore repellat? Ullam sed officia iure architecto deserunt distinctio",
//         'date': "Jan 29, 2018"
//       },
//       {
//         '_id': '5',
//         'title': 'Good morning india',
//         'image': "http://127.0.0.1:8000/media/dailynews/images/img101.jpg",
//         'description': "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est pariatur nemo tempore repellat? Ullam sed officia iure architecto deserunt distinctio",
//         'date': "Jan 29, 2018"
//       },
// ]



function News() {
  const [circularprogress,setCircularprogress] = useState('0');
  const [images,setImages] = useState([]);
  console.log(images)
  useEffect(() => {
    console.log('bkp')
    axios.post(gurl+'dailynews/allimage')
    .then((data)=>{setImages(data.data)})
    .catch((error)=> {
      console.log(error.response);
      });
  },[]);

    const HandleData=(e)=>{
      e.preventDefault();
      
      var keyword=null
      if(document.getElementById("keywordtext").value){
         keyword=document.getElementById("keywordtext").value
      }
      if(document.getElementById("keyworddd").value !=='0'){
         keyword=document.getElementById("keyworddd").value
      }
      
      let sort_by=document.getElementById("sort_by").value

      console.log(keyword,sort_by)
      var bodyFormData=new FormData();
      bodyFormData.append('keyword',keyword)
      bodyFormData.append('sort_by',sort_by)
        axios.post(gurl+'dailynews/start-news',bodyFormData)
      .then((data)=>{console.log(data)
        setCircularprogress('0')
        if(data.data.code===200){
          console.log(gurl+data.data.output_name);window.open(gurl+data.data.output_name);setImages(data.data.list)
        }
        else{
          console.log(data.data.error1)
          console.log(data.data.error2)
        }
      })
      .catch((error)=> {
        setCircularprogress('0')
        console.log(error.response);
      
      });
      setCircularprogress('1')
    }
    return (
      <div style={{marginTop:'50px'}}>
        <div>
          <form name="myForm" onSubmit={HandleData}>
              <center>
                  <div style={{marginTop:'30px'}}>
                    <label><h5>keywords:- </h5></label><input onChange={()=>{
                                                            if(document.getElementById("keywordtext").value){
                                                            document.getElementById("keyworddd").value='0'
                                                            }
                                                            else{
                                                              document.getElementById("keyworddd").value='education'
                                                            }

                    }
                                                                        } id='keywordtext' type='text'></input>
                     <select id='keyworddd' onChange={()=>document.getElementById("keywordtext").value=''} className="btn btn-warning dropdown-toggle" >
                        <option value='0'>None</option>
                        <option value="education" selected>Education</option>
                        <option value="finance" selected>Finance</option>
                        <option value="technology" selected>Technology</option>
                      </select>
                  </div>
                
                  <div style={{marginTop:'30px'}}>
                    <label><h5>sort_by</h5></label>
                      <select id='sort_by' className="btn btn-warning dropdown-toggle"  >
                        <option value="relevancy" selected>relevancy</option>
                        <option value="popularity">popularity</option>
                        <option value="publishedAt">publishedAt</option>
                      </select>
                  </div>
                  
                      {
                circularprogress==='1'?
                <div style={{marginTop:'30px'}}>
                <CircularProgress color="secondary" />
                </div>
                :
                <div style={{marginTop:'30px'}}>
                      <button type='submit'className="btn btn-success">Submit</button>
                      </div>
              }
                
              </center> 
          </form>
        </div>
        <div className="content-wrapper" style={{marginTop:'50px'}}>
              {
                images.map(image =>
                    (
                      // <NewsCard  key={NewsData._id} NewsData={NewsData} />
                      <div className="news-card">
                <Linkk to="#" className="news-card__card-link"></Linkk>
                <img src={gurl+image} alt="" className="news-card__image"/>
                {/* <div className="news-card__text-wrapper">
                <h2 className="news-card__title">dddd</h2>
                <div className="news-card__post-date">cccc</div>
                <div className="news-card__details-wrapper">
                    <p className="news-card__excerpt">bbbb&hellip;</p>
                    <a href="#" className="news-card__read-more">Read more <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
                </div> */}
            </div>
                    )
                  )
              }
        </div>
      </div>     
    )
}

export default News





// import DynamicSelect from 'react-dynamic-select'
// import React, { useState } from "react";

// function Appp() {
//   const [inputList, setInputList] = useState([{ firstName: ""}]);
//   console.log(inputList)

//   // handle input change
//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...inputList];
//     list[index][name] = value;
//     setInputList(list);
//   };

//   // handle click event of the Remove button
//   const handleRemoveClick = index => {
//     const list = [...inputList];
//     list.splice(index, 1);
//     setInputList(list);
//   };

//   // handle click event of the Add button
//   const handleAddClick = () => {
//     setInputList([...inputList, { firstName: ""}]);
//   };

//   return (
//     <div className="App">
//       <h3><a href="https://cluemediator.com">Clue Mediator</a></h3>
      

//       <form>
//       {inputList.map((x, i) => {
//         return (
//           <div className="box">
//             <select className="btn btn-warning dropdown-toggle" name="firstName"
//             onChange={e => handleInputChange(e, i)}
//           >
//               <option value="relevancy" selected>relevancy</option>
//               <option value="popularity">popularity</option>
//               <option value="publishedAt">publishedAt</option>
//             </select>
//             <div className="btn-box">
//               {inputList.length !== 1 && <button
//                 className="mr10"
//                 onClick={() => handleRemoveClick(i)}>Remove</button>}
//               {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
//             </div>
//           </div>
//         );
//       })}
//       </form>
//       <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
//     </div>
//   );
// }

// export default Appp;