import React from "react"
import Heroimg from "../Assets/SVG/Heroimg"
import HeroName from "../Assets/SVG/HeroName"
import { HashLink as Linkk } from 'react-router-hash-link';
const MainPage = () => {
    
    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };
    
    TxtType.prototype.tick = function() {
  
  
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];
    
        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
    
        this.el.innerHTML = '<span className="wrap">'+this.txt+'</span>';
    
        var that = this;
        var delta = 200 - Math.random() * 100;
    
        if (this.isDeleting) { delta /= 2; }
    
        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
        }
    
        setTimeout(function() {
        that.tick();
        }, delta);
    };
    
    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
        
    };
    return (
        <>
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
            <div className="row hero-box">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <HeroName></HeroName>
                <h1 className="d-flex justify-content-around hero-box ">Empowering thoughts with Code</h1>
               
                <div className="d-flex justify-content-around hero-box " style={{textAlign:'center',marginTop:'10px'}}>
                   <label className="justify-content-around typewrite hero-box" ><b className="justify-content-around typewrite hero-box bli" style={{}} data-period="2000" data-type='[ "Hi, Confused how to get started ??", "No worries", "We will help you out"]'>
                        <span className="wrap" ></span>
                        
                    </b></label> 
                    
                </div>
                    <div className="d-flex justify-content-around text-center ">
                    <Linkk to="#contact" className="btn-get-started d-flex justify-content-around text-decoration-none">
                        <span>Get Started</span>
                    </Linkk>
                    </div>
                </div>
                <div className="col-lg-6 hero-img">
                    <Heroimg className="img-fluid"></Heroimg>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}

export default MainPage;