import React, { useState } from 'react'
import { gurl } from './Global';

function Reviews() {

    const [items, setItems] = useState([]);

    React.useEffect(() => {
        window.ReviewAnimation();
        console.log("Testimonials....")

        fetch(gurl+"client_info/get_reviews")
        .then((response) => response.json())
        .then((data) => setItems(data.data));
    }, [])

    return (
        <>
        
        <header className="section-header">
            <p>Our reviews...</p>
        </header>
        <div class="container">
            <div id="demo" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">

            {
                items.map((item, index) => (
                    
                    // class="carousel-item active"
                    <div className={`carousel-item ${index == 0 ? "active" : ""}`} >
                        <div class="carousel-caption">
                            <p>{item[1]}</p> 
                            <div id="image-caption">{item[0]}</div>
                        </div>
                    </div>
                ))
            }
           

        </div> <a class="carousel-control-prev" href="#demo" data-slide="prev"> <i class='testimonials-i fas fa-arrow-left'></i> </a> <a class="carousel-control-next" href="#demo" data-slide="next"> <i class='testimonials-i fas fa-arrow-right'></i> </a>
    </div>
</div>

        </>
        
    )
}

export default Reviews
