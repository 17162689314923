import React,  { useState } from 'react'
import axios from "axios"
import { store } from 'react-notifications-component';
import {gurl} from './Global'
import { CircularProgress } from '@material-ui/core';
const ContactUs = () => {
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [subject,setSubject] = useState('');
    const [message,setMesage] = useState('');
    const [contacts,setContacts] = useState('');
    const Sendclient=event=>{
    event.preventDefault();
    var bodyFormData=new FormData();
    bodyFormData.append('name',name)
    bodyFormData.append('email',email)
    bodyFormData.append('phone',phone)
    bodyFormData.append('subject',subject)
    bodyFormData.append('message',message)
    axios.post(gurl+'client_info/contactus',bodyFormData)
    .then((data)=>{Set(data.data);setContacts(0)})
    .catch((error)=> {
      console.log(error.response);
      setContacts(0) 
      });
    setContacts(1)
    }
    const Set=(status)=>{
      console.log('status==')
      console.log(status)
      setName('')
      setEmail('')
      setPhone('')
      setSubject('')
      setMesage('')
      status.success===true?
      Noti('Thank You For Contact Us','success')
      :
      Noti('Please Try Again','warning')
    }
    

return (
  <>
    <section id="contact" className="contact">

    <div className="container" data-aos="fade-up">

      <header className="section-header">
              <p>Contact Us</p>
      </header>

  <div className="row gy-4 ">

    <div className="col-lg-6">
      <div className="col gy-4">
                <div className="row-md-6 text-center">
                  <div className="info-box">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#dc4a38" className="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  </svg>
                    <h3>Address</h3>
                    <p>Bhubaneswar<br/>Odisha<br/>India, 751024</p>
                  </div>
                </div>

                          <br/>

                <div className="row-md-6 text-center">
                  <div className="info-box">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#dc4a38" className="bi bi-chat-quote" viewBox="0 0 16 16">
                  <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                  <path d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"/>
                  </svg>
                    <h3>Our Info</h3>
                    <p>+91 7978431307<br/>support@clowndev.com</p>
                </div>
                </div>
      </div>
    </div>

    <div className="col-lg-6">
      <form  className="php-email-form" onSubmit={Sendclient}>
        <div className="row gy-4">

          <div className="col-md-12">
            <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)}  placeholder="Your Name" required/>
          </div>

          <div className="col-md-6">
            <input type="email"  className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="name@email.com" required/>
          </div>

          <div className="col-md-6 ">
            <input type="text" className="form-control" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Phone No." required/>
          </div>

          <div className="col-md-12">
            <input type="text" className="form-control" value={subject} onChange={(e)=>setSubject(e.target.value)} placeholder="Subject" required/>
          </div>

          <div className="col-md-12">
            <textarea className="form-control" value={message} onChange={(e)=>setMesage(e.target.value)} rows="2" placeholder="Message" required></textarea>
          </div>

          <div className="col-md-12 text-center">
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">Your message has been sent. Thank you!</div>

            <button type="submit">Send Message</button>
            {
						contacts===1?
            <div>
						<CircularProgress color="secondary" />
            </div>
						:
						<></>
					}
          </div>

        </div>
      </form>

    </div>

  </div>

</div>

</section>
  </>
)
}

export default ContactUs;

export const Noti=(message,type)=>{
  store.addNotification({
      title: 'ClownDev',
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
}